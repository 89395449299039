<template>
  <div>
    <validation-observer ref="assistantForm">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col>
            <b-card no-body>
              <b-card-header>
                <div>
                  <b-card-title>ข้อมูลผู้ช่วย</b-card-title>
                </div>
                <!-- <h4 class="m-0">
                  <b-link @click="">
                    แก้ไขข้อมูล
                  </b-link>
                </h4> -->
              </b-card-header>
              <hr class="m-0">
              <b-card-body>
                <b-row>
                  <b-col
                    sm="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <!-- password -->
                      <b-form-group
                        label-for="password"
                        label="*รหัสผ่าน"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="password"
                            v-model="assistantForm.password"
                            :type="passwordFieldType"
                            class="form-control-merge"
                            :state="errors[0] ? false : null"
                            name="password"
                            placeholder="Password"
                          />

                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-group
                        label-for="name"
                        label="*ชื่อ"
                      >
                        <b-form-input
                          id="name"
                          v-model="assistantForm.name"
                          name="name"
                          :state="errors[0] ? false : null"
                          placeholder="ตัวอย่าง: สมชาย ขายแกง"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <b-form-group
                        label-for="phone"
                        label="*เบอร์โทรศัพท์"
                      >
                        <b-form-input
                          id="phone"
                          v-model="assistantForm.phone"
                          name="phone"
                          :state="errors[0] ? false : null"
                          placeholder="ตัวอย่าง: 081-234-5678"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules=""
                    >
                      <b-form-group
                        label-for="email"
                        label="อีเมล"
                      >
                        <b-form-input
                          id="email"
                          v-model="assistantForm.email"
                          name="email"
                          :state="errors[0] ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                  >
                    <label for="" />
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="assistantForm.isActive"
                        name="is-active"
                        switch
                        class="custom-control-primary"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <span>
                        การเข้าใช้งาน
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body>
              <b-card-header>
                <b-card-title>สิทธิ์ในการเข้าถึงข้อมูล</b-card-title>
              </b-card-header>
              <hr class="m-0">
              <b-card-body>
                <b-row>
                  <b-col cols="6">
                    <div
                      v-for="item in menu"
                      :key="item.name"
                      class="d-flex"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          v-model="item.isSelect"
                          :name="item.name"
                          switch
                          class="custom-control-secondary"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                      <span>
                        {{ item.name }}
                      </span>
                    </div>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group>
                      <div class="d-flex">
                        <b-form-checkbox
                          v-model="assistantForm.hasOwnerRight"
                          name="hasOwnerRight"
                          switch
                          class="custom-control-primary"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="d-flex flex-column">
                          สิทธิ์การเป็น Owner
                          <b-form-text>สามารถเข้าได้ถึงทุกเมนู รวมถึงเมนูผู้ช่วย</b-form-text>
                        </span>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-center">
              <b-button
                variant="outline-primary"
                class="mr-1"
                to="/assistants"
              >
                ยกเลิก
              </b-button>
              <b-overlay :show="isSubmitting">
                <b-button
                  variant="primary"
                  type="submit"
                  block
                >
                  เข้าสู่ระบบ
                </b-button>
              </b-overlay>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {},

  mixins: [togglePasswordVisibility],

  props: {
    agentId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // validation rules
      required,
      email,

      isFailed: false,

      isShowPassword: false,
      assistantForm: {
        password: '',
        name: '',
        phone: '',
        email: '',
        isActive: true,
        hasOwnerRight: false,
      },

      menu: [
        { name: 'เก็บเงินตัวแทน', isSelect: false },
        { name: 'รายชื่อตัวแทน', isSelect: false },
        { name: 'รายการเกม', isSelect: false },
        { name: 'ประวัติเล่นเกม', isSelect: false },
        { name: 'บอท', isSelect: false },
        { name: 'ตั้งค่าระบบ', isSelect: false },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.assistant.isGettingAssistantDetail,
      isSubmitting: (state) =>
        state.request.isCreatingAssistant || state.request.isUpdatingAssistant,
    }),
    ...mapGetters(['assistantDetail']),
    assistantId() {
      const { name, params } = this.$route
      return name === 'assistant_create' ? null : params.id || ''
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    'assistantForm.hasOwnerRight'(val) {
      if (val) {
        this.menu.forEach((item) => {
          item.isSelect = true
        })
      }
    },
    assistantDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getAssistant', 'createAssistant', 'updateAssistant']),
    fetchData() {
      if (this.assistantId) {
        this.getAssistant(this.assistantId)
      }
    },
    setData() {
      if (this.assistantDetail.id) {
        const data = JSON.parse(JSON.stringify(this.assistantDetail))
        this.assistantForm = { ...data }
      } else {
        this.assistantForm = {
          password: '',
          name: '',
          phone: '',
          email: '',
          isActive: true,
          hasOwnerRight: false,
        }
      }
    },
    onSubmit() {
      this.$refs.assistantForm.validate().then(async (success) => {
        if (success) {
          if (this.assistantId) {
            this.createAssistant(this.assistantForm)
          } else {
            this.updateAssistant({
              id: this.assistantId,
              data: this.assistantForm,
            })
          }
        }
      })
    },
  },
}
</script>
